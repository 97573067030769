import Logger from 'services/Logging/logService';

export const convertItemId = (itemId: string): string  => {
    if (Office.context.mailbox.diagnostics.hostName === 'OutlookIOS') {
        // itemId is already REST-formatted.
        Logger.debug('host=OutlookIOS, not converting');

        return itemId;
    } else {
        // Convert to an item ID for API v2.0.
        Logger.debug('converting from ewsId', itemId);

        return Office.context.mailbox.convertToRestId(
            itemId,
            Office.MailboxEnums.RestVersion.v2_0
        );
    }
}

import { Suspense, lazy, useContext } from 'react';
import { Stack, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { ErrorMessage } from 'components/Notification/ErrorMessage';
import SceneLoader from 'components/Loading/SceneLoader';
import AppContext from './AppContext';
import ContentLoader from 'components/Loading/ContentLoader';
import { darkTheme, lightTheme } from '../globalThemes';
import AppContentAreaTopLayerHost from './AppContentAreaTopLayerHost';
import { ThemeProvider } from '@fluentui/react';
import { ToastContainer } from 'react-toastify';
import { globalToastDelay } from 'globalConstants';

//
// Lazy load all scenes (React module splitting)
//
const TaskOverview = lazy(() => import('scenes/Task/TasksOverview'));
const ChooseTask = lazy(() => import('scenes/ChooseTask/ChooseTask'));
const NotFound = lazy(() => import('scenes/NotFound/NotFound'));
const Welcome = lazy(() => import('scenes/Enroll/Welcome'));
const FirstRun = lazy(() => import('scenes/Enroll/FirstRun'));

const AppRouting = () => {
  const appContext = useContext(AppContext);

  return (
    <ThemeProvider applyTo="body" theme={appContext.useDarkMode ? darkTheme : lightTheme}>
      <Stack verticalFill styles={{ root: { height: '100vh' } }}>
        {/* Main App */}

        <Stack.Item>
          {/* Notification area */}
          <AppContentAreaTopLayerHost />
          <ContentLoader />
          <ErrorMessage error={appContext.error} setError={appContext.setError} />
          <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="app" />
        </Stack.Item>

        <Stack.Item grow styles={{ root: { position: 'relative' } }}>
          {/* Scene area */}
          <Suspense fallback={<SceneLoader />}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              {!appContext.isReady() && <SceneLoader />}

              {appContext.isReady() &&
                appContext.isAuthenticated &&
                appContext.firstRun === false &&
                appContext.user.login.lastLogin === undefined && <FirstRun onStart={appContext.startFirstRun} />}

              {appContext.isReady() &&
                appContext.isAuthenticated &&
                appContext.task !== undefined &&
                !appContext.isNewAppointment &&
                !(appContext.firstRun === false && appContext.user.login.lastLogin === undefined) && (
                  <TaskOverview task={appContext.task} logout={appContext.logout}></TaskOverview>
                )}

              {appContext.isReady() &&
                appContext.isAuthenticated &&
                appContext.task === undefined &&
                !appContext.isNewAppointment && <NotFound></NotFound>}

              {appContext.isReady() &&
                appContext.isAuthenticated &&
                appContext.task === undefined &&
                appContext.isNewAppointment && <ChooseTask></ChooseTask>}

              {appContext.isReady() && !appContext.isAuthenticated && <Welcome login={appContext.login}></Welcome>}
            </ScrollablePane>
          </Suspense>
        </Stack.Item>
      </Stack>
    </ThemeProvider>
  );
};

export default AppRouting;

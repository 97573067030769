import Task from '../tasks/task';

export default class OutlookTasks {
  tasks: Task[];

  orgUnitId?: string;

  constructor() {
    this.tasks = [];
  }
}
